import styled from "styled-components";

const StoresStyleWrapper = styled.div`
header{
  background-color: transparent;
  padding:0px;
  h1{
    font-weight: 900;
    font-size: 24px;
    color: #272B41;
    margin-bottom: 0;
    height:43px;
  }
}
h6{
  font-weight: 600;
  font-size:24px ;
}
input{
  border: 1px solid #ddd;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 14px;
    max-width: 100px;
}
button.button-close{
  display: block;
  margin-left: auto;
  margin-top: 24px;
}
button{
    background:  #2589ff;
    color: #fff;
    font-weight: 600;
    border-radius: 4px;
    border: none;
}
.modal-table-box {
    margin-top: 24px;
    p{
      margin: 0;
    }
}
.table-box{
  margin-top: 24px;
  p.store-name {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: #2589ff;
    cursor: pointer;
}
p{
  margin: 0;
}
}
`;
export { StoresStyleWrapper };
