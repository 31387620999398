import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

const axios = axiosBaseUrl();

export const GetGiftCards = createAsyncThunk(
  'giftCards/getGiftCards',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/gift-card/get-gift-cards', {
        params: data
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status
          }
        });
      }

      return thunkAPI.rejectWithValue({
        err: {
          message: 'Network Error',
          status: 502
        }
      });
    }
  }
);

const giftCard = createSlice({
  name: 'giftCards',
  initialState: {
    success: false,
    loading: false,
    giftCards: [],
    totalGiftCards: 0,
    error: '',
    message: ''
  },
  reducers: {
    SetGiftCardState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    clearState(state, action) {
      return {
        ...state
      };
    }
  },
  extraReducers: {
    [GetGiftCards.pending]: (state, action) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetGiftCards.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      totalGiftCards: action.payload.totalGiftCards,
      giftCards: action.payload.giftCards
    }),
    [GetGiftCards.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      error: action.payload.err.message
    })
  }
});

const { reducer, actions } = giftCard;

export const {
  clearState,
  SetGiftCardState
} = actions;

export default reducer;
