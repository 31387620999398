import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  Input
} from 'antd';

import Table from '../../component/table/Index'
import TablePagination from '../../component/pagination';
import SearchInput from '../../component/searchInput/Index';

import { GetGiftCards } from '../../redux/slices/gift-card-slice';

import { StoresStyleWrapper } from './style';

const Stores = () => {
  const dispatch = useDispatch();

  const {
    giftCards,
    totalGiftCards
  } = useSelector(state => state.giftCard);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [sortObj, setSortObj] = useState({
    sortColumn: 'brandName',
    sortType: 'asc'
  });
  const [cartModal, setCartModal] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [modalTableRows, setModalTableRows] = useState([]);

  const handleModalRows = (cards) => {
    setSelectedBrand(cards[0].brandName);
    const data = cards.map((obj) => {
      const {
        value,
        discount,
        quantityAvailable
      } = obj;

      let stock = !quantityAvailable ? 'Out of stock' : quantityAvailable;
      if (quantityAvailable > 10 && quantityAvailable <= 100) {
        stock = '10+ available';
      } else if (quantityAvailable > 100) {
        stock = '100+ available';
      } else if (quantityAvailable > 0) {
        stock = `${quantityAvailable} available`;
      }

      const price = value - (value * discount);

      return {
        value: `$${value.toFixed(2)}`,
        price: `$${price.toFixed(2)}`,
        discount: `${(discount * 100).toFixed(2)}% Off`,
        delivery: 'Electronic',
        status: stock
      }
    });

    setModalTableRows(data);
    setCartModal(true);
  };

  const columnDefinition = [{
      headerName: 'Store',
      field: 'store',
      minWidth: 500,
      resizable: true,
      cellRenderer: (params) => <p className='store-name' onClick={() => handleModalRows(params.data.cards)}>{params.data.store}</p>
    }, {
      headerName: 'Card Value',
      field: 'cardValue',
      minWidth: 500, 
      resizable: true
    }, {
      headerName: 'Discount Rate',
      field: 'price',
      minWidth: 500,
      resizable: true,
  }];

  const modalTableColumnDefinition = [{
      headerName: 'Value',
      field: 'value',
      width:140,
      resizable: true
    }, {
      headerName: 'Price',
      field: 'price',
      width:140,
      resizable: true
    }, {
      headerName: 'Discount',
      field: 'discount',
      resizable: true,
      width:140
    },
    {
      headerName: 'Delivery',
      field: 'delivery',
      resizable: true,
      width:140
    },
    {
      headerName: 'Status',
      field: 'status',
      resizable: true,
      width:140
    }, {
      headerName: '',
      field: '',
      minWidth: 350,
      resizable: true,
      cellRenderer: (params) => (
        <div>
          <Input
            type="number"
            min={0}
            max={100}
          />
          <Button>Add To Cart</Button>
        </div>
      )
  }];

  const defaultColDefs = {
    resizable: true,
    sortable: true
  };

  // useEffect(() => {
  //   dispatch(GetGiftCards({
  //     searchKeyword,
  //     sortColumn: 'brandName',
  //     sortType: 'asc',
  //     skip: 0,
  //     limit: 10
  //   }));
  // }, []);

  useEffect(() => {
    if (giftCards?.length) {
      const data = giftCards.map((obj, index) => {
        const {
          brandName: store,
          cards,
          minValue,
          maxValue,
          minDiscount,
          maxDiscount
        } = obj;

        const cardValue = cards?.length > 1 ? `$${minValue} - $${maxValue}` : `$${minValue}`;
        const price = cards?.length > 1 ? (minDiscount === maxDiscount) ? `${(minDiscount * 100).toFixed(2)}%` : `${(minDiscount * 100).toFixed(2)}% - ${(maxDiscount * 100).toFixed(2)}%` : `${(minDiscount * 100).toFixed(2)}%`;

        return {
          id: index,
          store,
          cardValue,
          price,
          cards
        }
      });
      console.log('\n final data', data);

      setTableRows(data);
    } else {
      setTableRows([]);
    }
  }, [giftCards]);

  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(GetGiftCards({
        searchKeyword,
        sortColumn: sortObj.sortColumn,
        sortType: sortObj.sortType,
        skip: (pageNumber - 1) * pageLimit,
        limit: pageLimit
      }));
    }, 500);

    return () => clearTimeout(getData);
  }, [pageNumber, pageLimit, sortObj.sortColumn, sortObj.sortType, searchKeyword]);

  const onSortChanged = ({ api: { sortController } }) => {
    const sortModel = sortController.getSortModel();
    if (sortModel.length > 0) {
      
      const { colId: sortColumn, sort: sortType } = sortModel[0];
      let sColumn;
      if (sortColumn === 'store') {
        sColumn = 'brandName';
      } else if (sortColumn === 'cardValue') {
        sColumn = 'value'
      } else if (sortColumn === 'price') {
        sColumn = 'discount';
      }

      setSortObj({
        sortColumn: sColumn,
        sortType
      });
    }
  };

  const onFilterChanged = (filterModel) => {
  };

  const onSelectionChanged = (selectedRows) => {
  };

  const isRowSelectable = (rowNode) => {
    return true;
  };

  const handleOnPageChange = (newPageNumber, newPageLimit) => {
    setPageNumber(newPageNumber);
    setPageLimit(newPageLimit);
  };

  const handleChange = (value) => {
    setSearchKeyword(value);

    setPageNumber(1);
  };

  return (
    <StoresStyleWrapper>
      <header>
        <h1 className="page-title">Gift Cards</h1>
        <div className="filter-search">
          <SearchInput
            placeholder="Search Stores"
            onChange={(e) =>
              handleChange(e.target.value)
            }
          />
        </div>
      </header>
      <div className="table-box">
        <Table
          height='204px'
          headerHeight={50}
          columnDefinitions={columnDefinition}
          defaultColDef={defaultColDefs}
          rowSelection='multiple'
          onSortChange={onSortChanged}
          rowData={tableRows}
          rowHeight={67}
          onFilterChanged={onFilterChanged}
          loading={loading}
          onSelectionChanged={onSelectionChanged}
          isRowSelectable={isRowSelectable}
        />
        <TablePagination
          background="#F8F8F8"
          records={totalGiftCards}
          // options={pageOptions}
          onChange={handleOnPageChange}
          pageNumber={pageNumber}
          pageLimit={pageLimit}
        />
        <Modal open={cartModal} width={1100} footer={null} onCancel={() => setCartModal(false)}>
          <StoresStyleWrapper>
            <h6>{selectedBrand}</h6>
            <div className="modal-table-box">
              <Table
                height={'500px'}
                headerHeight={50}
                columnDefinitions={modalTableColumnDefinition}
                defaultColDef={modalTableRows}
                rowSelection='multiple'
                onSortChange={onSortChanged}
                rowData={modalTableRows}
                rowHeight={67}
                onFilterChanged={onFilterChanged}
                loading={loading}
                onSelectionChanged={onSelectionChanged}
                isRowSelectable={isRowSelectable}
              />
            </div>
            <Button className='button-close' onClick={() => setCartModal(false)}>Close</Button>
          </StoresStyleWrapper>
        </Modal>
      </div >
    </StoresStyleWrapper >
  )
}

export default Stores;
